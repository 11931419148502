<script lang="ts">
	import type { CurrentUser } from '$lib/stores/auth-state'
	import Avatar from '$lib/components/profiles/Avatar.svelte'
	import HelpChatIcon from '$lib/components/icons/HelpChatIcon.svelte'
	import LogoutIcon from '$lib/components/icons/LogoutIcon.svelte'
	import ShopIcon from '$lib/components/icons/ShopIcon.svelte'
	import { track } from '$lib/utils/track'
	import { goto } from '$app/navigation'
	import CompassIcon from '$lib/components/icons/CompassIcon.svelte'
	import LocationPinIcon from '$lib/components/icons/LocationPinIcon.svelte'
	import Menu from '$lib/components/navigation/Menu.svelte'
	import MenuButton from '$lib/components/navigation/MenuButton.svelte'
	import MenuItems from '$lib/components/navigation/MenuItems.svelte'
	import MenuTransition from '$lib/components/navigation/MenuTransition.svelte'
	import MenuItem from '$lib/components/navigation/MenuItem.svelte'

	interface Props {
		class?: string
		embedded?: boolean
		user?: CurrentUser | null
		onsignout?: () => void
	}

	let { class: className = '', embedded = false, user = null, onsignout }: Props = $props()

	let avatarSrc = $derived(
		user?.currentProfilePictureUrl && user.currentProfilePictureUrl != ''
			? user.currentProfilePictureUrl
			: '/visuals/icons/default-user.svg',
	)

	function handleSignOut() {
		onsignout?.()
	}

	const openMailer = () => {
		track('Open Mailer', { category: 'Footer' })
		window.open('https://intercom.help/airheart/en', '_blank')
	}

	const openStore = () => {
		track('Store Clicked')
		goto('/store')
	}

	let name = $derived(user?.currentName || '')
</script>

<Menu class={className}>
	<MenuButton>
		<Avatar size="md" src={avatarSrc} alt={user.currentName} />
	</MenuButton>
	<MenuTransition>
		<MenuItems isStatic={embedded}>
			<div
				class={`${
					embedded
						? 'w-full bg-transparent pt-8'
						: 'py-2 top-2 w-48 shadow-2xl rounded-md ring-1 ring-black ring-opacity-5'
				} origin-top-right absolute right-0 text-black bg-white divide-y divide-gray-200 z-[4999]`}
			>
				<div class="px-4 py-4 text-center select-none">
					<Avatar size="lg" src={avatarSrc} alt={name} />
					<div class="mt-2 leading-4">{name ?? 'No name'}</div>
					<div class="m-0 mt-1 text-xs leading-4 text-brand-primary truncate text-ellipsis">
						{user.email}
					</div>
				</div>
				<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
					<MenuItem
						onclick={() => goto('/trips')}
						label="Your Trips"
						class="text-black dark:text-black py-2 px-6"
					>
						<LocationPinIcon class="h-4 w-4 text-black" />
					</MenuItem>
					<MenuItem
						onclick={() => goto('/explore')}
						label="Explore Guides"
						class="text-black dark:text-black py-2 px-6"
					>
						<CompassIcon class="h-4 w-4 text-black" />
					</MenuItem>
					<MenuItem
						onclick={openStore}
						label="Your Store"
						class="text-black dark:text-black py-2 px-6"
					>
						<ShopIcon class="h-4 w-4" />
					</MenuItem>
					<MenuItem
						onclick={openMailer}
						label="Get Help"
						class="text-black dark:text-black py-2 px-6"
					>
						<HelpChatIcon class="h-3 w-3 mx-[2px] text-black" />
					</MenuItem>
				</div>
				<div class="pt-1">
					<MenuItem
						onclick={handleSignOut}
						label="Log Out"
						class="text-black dark:text-black py-2 px-6"
					>
						<LogoutIcon class="h-4 w-4" />
					</MenuItem>
				</div>
			</div>
		</MenuItems>
	</MenuTransition>
</Menu>
