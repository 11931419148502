<script lang="ts">
	import MainFooter from '$lib/components/footers/MainFooter.svelte'
	import MainNavigation from '$lib/components/navigation/MainNavigation.svelte'
	import '../../app.css'
	import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
	import { setContextClient } from '@urql/svelte'
	import Modals from '$lib/components/modals/modals.svelte'
	import { page } from '$app/state'
	import SvelteSeo from 'svelte-seo'
	import YoloAuth from '$src/lib/components/auth/YoloAuth.svelte'
	import { browser } from '$app/environment'
	import { onMount } from 'svelte'

	// Lazy-loaded components
	let GoogleTagManager = $state(null)
	let FullStory = $state(null)

	onMount(async () => {
		if (browser) {
			;[GoogleTagManager, FullStory] = await Promise.all([
				import('$lib/components/analytics/GoogleTagManager.svelte').then((m) => m.default),
				import('$lib/components/analytics/FullStory.svelte').then((m) => m.default),
			])
		}
	})

	interface Props {
		children?: import('svelte').Snippet
	}

	let { children }: Props = $props()

	let title = $derived(page?.data?.title)
	let description = $derived(page?.data?.description)

	setContextClient(normalizedClient)
</script>

<svelte:head>
	<link rel="shortcut icon" href="/favicon.ico" />
	<meta name="theme-color" content="#FF006E" />
	<title>{title}</title>
	<meta name="description" content={description} />
</svelte:head>

<div class="min-h-dvh bg-white -mb-[8px]" data-sveltekit-preload-data="hover">
	<!-- {title} -->
	<SvelteSeo
		jsonLd={{
			'@type': 'Corporation',
			logo: 'https://airheart.com/airheart-logo-full-new.svg',
			url: 'https://airheart.com',
			name: 'Airheart, Inc.',
			sameAs: [
				'https://twitter.com/AirheartInc',
				'https://instagram.com/airheartinc',
				'https://www.linkedin.com/company/airheartinc',
				'https://www.facebook.com/AirheartInc',
			],
		}}
	/>

	<MainNavigation />
	<div class="bg-white">
		{@render children?.()}
	</div>
	<MainFooter />
</div>

<Modals />
<YoloAuth />
{#if browser && GoogleTagManager}
	{@render GoogleTagManager()}
{/if}
{#if browser && FullStory}
	{@render FullStory()}
{/if}
